import PropTypes from 'prop-types';
import React from 'react';
import { UX2, constants as coreConstants } from '@wsb/guac-widget-core';
import dataAids from '../constants/dataAids';
import { headerImage } from '../constants/propTypes';
import { FILL, FIT, INSET, BLUR, LEGACY_BLUR } from '../../../../constants/headerTreatments';
import { CATEGORY, PRIMARY, NEUTRAL, ACCENT, NONE } from '../../../../constants/overlayTypes';

const { DEFAULT, ALT, OVERLAY } = coreConstants.sectionTypes;
const LEGACY_BLUR_RADIUS = 30;

const BackgroundImage = ({
  data,
  blur,
  children,
  dataRoute,
  dataAid,
  overlay,
  treatment,
  style = {},
  backgroundSize,
  section,
  showOverlay
}) => {
  const { image: backgroundImage, position: backgroundPosition, alt, overlayAlpha } = data;

  if (!backgroundImage) {
    return <UX2.Element.Block>{ children }</UX2.Element.Block>;
  }

  const isLegacyBlur = treatment === LEGACY_BLUR;
  const bgSize = backgroundSize || 'cover';

  const bgStyle = {
    ...((backgroundPosition || '').indexOf(' ') > -1 ? { backgroundPosition } : {}),
    ...style
  };

  if (isLegacyBlur) {
    return (
      <UX2.Component.Background
        backgroundSize='contain'
        imageData2={{ ...data, additionalUrlParams: [`fx-bl=s:${LEGACY_BLUR_RADIUS}`] }}
        backgroundSize2='cover'
        data-aid={ dataAid }
        data-field-id={ dataRoute }
        aria-label={ alt }
        section={ OVERLAY }
        style={ bgStyle }
        overlay={ overlay }
        treatment={ treatment }
        data-ht={ treatment }
        overlayAlpha={ showOverlay ? overlayAlpha : 0 }
        imageData={ data }
      >
        { children }
      </UX2.Component.Background>
    );
  }

  return (
    <UX2.Component.Background
      data-aid={ dataAid }
      data-field-id={ dataRoute }
      aria-label={ alt }
      section={ section }
      style={ bgStyle }
      overlay={ overlay }
      treatment={ treatment }
      data-ht={ treatment }
      backgroundSize={ bgSize }
      overlayAlpha={ showOverlay ? overlayAlpha : 0 }
      imageData={ data }
      blur={ blur }
    >
      { children }
    </UX2.Component.Background>
  );
};

BackgroundImage.propTypes = {
  blur: PropTypes.bool,
  data: headerImage.isRequired,
  dataAid: PropTypes.string,
  children: PropTypes.any,
  overlay: PropTypes.oneOf([CATEGORY, PRIMARY, NEUTRAL, ACCENT, NONE]),
  section: PropTypes.oneOf([DEFAULT, ALT, OVERLAY]),
  style: PropTypes.object,
  treatment: PropTypes.oneOf([FILL, FIT, INSET, BLUR, LEGACY_BLUR]),
  backgroundSize: PropTypes.string,
  mobileSize: PropTypes.string,
  dataRoute: PropTypes.string,
  showOverlay: PropTypes.bool
};

BackgroundImage.defaultProps = {
  blur: false,
  dataAid: dataAids.BACKGROUND_IMAGE_RENDERED,
  overlay: NONE,
  section: OVERLAY,
  showOverlay: true // default as true to prevent a theme that does not pass showOverlay from having a non-functional overlayAlpha slider
};

export default BackgroundImage;
