import { Component } from 'react';
import PropTypes from 'prop-types';

class SlideshowEditControl extends Component {
  componentDidUpdate() {
    const { goToSlide, editingIndex } = this.props;
    if (typeof editingIndex === 'number' && editingIndex >= 0) {
      goToSlide(editingIndex);
    }
  }

  render() {
    return null;
  }
}

SlideshowEditControl.propTypes = {
  renderKey: PropTypes.number,
  editingIndex: PropTypes.number,
  goToSlide: PropTypes.func
};

export default SlideshowEditControl;
