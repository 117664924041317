import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { UX2 } from '@wsb/guac-widget-core';
import PortalContainer from '../PortalContainer';

class SlideshowDots extends Component {
  goToSlide = (index, e) => {
    e.stopPropagation();
    this.props.goToSlide(index);
  };

  render() {
    const { numSlides, selectedIndex, editingIndex, visible, containerId } = this.props;
    if (numSlides <= 1) {
      return null;
    }

    const styles = {
      list: {
        'listStyle': 'none',
        'padding': 0,
        'marginTop': 0,
        'marginBottom': containerId ? 0 : 'medium',
        '@md': {
          marginBottom: containerId ? 0 : 'large'
        }
      },
      listItem: {
        display: 'inline-block',
        cursor: 'pointer'
      },
      wrapper: {
        display: visible ? 'block' : 'none',
        position: 'absolute',
        bottom: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        width: '100%',
        zIndex: 2
      }
    };

    const dots = [];
    for (let index = 0; index < numSlides; index++) {
      const Dot = index === selectedIndex ? UX2.Element.Dot.Active : UX2.Element.Dot;
      const dataProps =
        editingIndex >= 0
          ? {
            'data-field-id': 'slides',
            'data-field-route': `/mediaData/slides/${index}`
          }
          : {
            'data-edit-interactive': true
          };

      dots.push(
        <UX2.Element.Block
          tag='li'
          key={ `dot-${index}` }
          style={ styles.listItem }
          onClick={ this.goToSlide.bind(null, index) }
          { ...dataProps }
        >
          <Dot data-aid={ `SLIDE_SELECTION_DOT_${index}` } />
        </UX2.Element.Block>
      );
    }

    const dotsList = (
      <UX2.Element.Block tag='ul' style={ styles.list }>
        { dots }
      </UX2.Element.Block>
    );

    if (containerId) {
      return <PortalContainer containerId={ containerId }>{ dotsList }</PortalContainer>;
    }

    return <UX2.Element.Block style={ styles.wrapper }>{ dotsList }</UX2.Element.Block>;
  }
}

SlideshowDots.propTypes = {
  numSlides: PropTypes.number,
  selectedIndex: PropTypes.number,
  goToSlide: PropTypes.func,
  editingIndex: PropTypes.number,
  visible: PropTypes.bool,
  containerId: PropTypes.string
};

export default SlideshowDots;
